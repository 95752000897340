import { AppModule, VuexModuleConstructor } from '@/common/types'
import CalendarStore from './CalendarStore'
import CalendarModule from './CalendarModule'

export class Calendar extends AppModule<CalendarStore, CalendarModule> {
  public get name(): string {
    return 'calendar'
  }

  protected get storeClass(): VuexModuleConstructor<CalendarStore> {
    return CalendarStore
  }
}

export default CalendarModule
