import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import $app from '@/plugins/modules'
import store from '@/plugins/store'

const url = '/dplus/api/calendar/'

@Module({ dynamic: true, store, name: 'calendar' })
export default class CalendarStore extends VuexModule {
    _test: string = '';

    get getTest(): string {
      return this._test
    }

    // ---------------------------------------------------------------------- MUTATIONS

    @Mutation
    setTest(test: any) {
      this._test = test
    }

    // ---------------------------------------------------------------------- ACTIONS

    @Action({ rawError: true })
    async testCalendar() {
      $app.log('Loading system environment ...')
      try {
        const test: string = await $app.get(url + 'test', {})
      } catch (error) {
        $app.pushError(error)
      }
    }
}
