
import { Vue, Component } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { Calendar } from '@/modules/calendar'
import { IVisit } from '../../customers/types'
import { IPlace } from '../../clinic/types'
import { IUser } from '../../system/types'

@Component
export default class CalendarView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  calendar = $app.module(Calendar);

  loading = false;
  selectedY = '-10px';
  showDateSelector = false;
  dateValue = new Date();
  calendarType = 'category';
  calendarMode = 'stack';
  calendarComponent: any = null;
  weekday = [1, 2, 3, 4, 5, 6];

  visits: Array<IVisit> = [];
  places: Array<IPlace> = [];
  selectedPlaces: Array<number> = []
  placesIndex: Record<number, IPlace> = {};
  doctors: Array<IUser> = [];
  selectedDocs: Array<number> = []

  visitShow = false;
  visitId: number | null = null;
  visitPlace: number | null = null;
  visitPatient: number | null = null;
  visitDoc: number | null = null;
  visitFrom: Date | null = null;
  visitTo: Date | null = null;
  visitsMonth: Array<IVisit> = [];

  nextDate() {
    const newDate = new Date(this.date)
    if (this.calendarType === 'month') {
      newDate.addMonth(1)
    } else if (this.calendarType === 'week') {
      newDate.addDay(7)
    } else {
      newDate.addDay(1)
    }
    this.changeDate(newDate)
  }

  prevDate() {
    const newDate = new Date(this.date)
    if (this.calendarType === 'month') {
      newDate.addMonth(-1)
    } else if (this.calendarType === 'week') {
      newDate.addDay(-7)
    } else {
      newDate.addDay(-1)
    }
    this.changeDate(newDate)
  }

  changeDate(value: Date) {
    this.date = new Date(value);
    this.reloadVisits();
  }

  switchToDay(value: Date) {
    this.date = new Date(value);
    this.calendarType = 'category'
    this.reloadVisits();
  }

  getEventColor(event: any) {
    return event.color
  }

  get visitDates(): Array<string> {
    return this.visitsMonth.map(v => { return v.visitDate; });
  }

  get canceled(): any {
    const rangeFrom = new Date(this.curDate)
    const rangeTill = new Date(this.curDate).addDay(1)
    return this.visits
      .filter(visit => visit.state === 'C')
      .filter(visit => this.selectedPlaces.length === 0 || this.selectedPlaces.some(p => p === visit.place))
      .filter(visit => this.selectedDocs.length === 0 || this.selectedDocs.some(p => p === visit.doctor))
      .filter(visit => new Date(visit.timeFrom || '') > rangeFrom && new Date(visit.timeFrom || '') < rangeTill)
      .map(visit => {
        const place = this.placesIndex[visit.place || 0] || null;
        const doctor = place ? place.docIndex[visit.doctor || 0] || null : null;
        const debt = ((visit.payedAmount || 0) + visit.prepaid) - (visit.totalAmount || 0);

        const timeFrom = new Date(visit.timeFrom || '');
        const timeTo = new Date(visit.timeTo || '');

        return {
          name: visit.patientName,
          start: visit.timeFrom,
          end: visit.timeTo,
          color: 'white',
          category: place ? place.name : '',
          visitColor: doctor ? doctor.color || '' : '',
          timed: true,
          timeFrom,
          timeTo,
          debt,
          size: 1,
          visitStateColor: 'disabled',
          doctor,
          place,
          visit
        }
      });
  }

  get events(): any {
    return this.visits
      .filter(visit => visit.state !== 'C')
      .filter(visit => this.selectedPlaces.length === 0 || this.selectedPlaces.some(p => p === visit.place))
      .filter(visit => this.selectedDocs.length === 0 || this.selectedDocs.some(p => p === visit.doctor))
      .map(visit => {
        const place = this.placesIndex[visit.place || 0] || null;
        const doctor = place ? place.docIndex[visit.doctor || 0] || null : null;
        const debt = ((visit.payedAmount || 0) + visit.prepaid) - (visit.totalAmount || 0);

        let stateColor = ''
        if (visit.state === 'P') {
          stateColor = 'error';
        } else if (visit.state === 'A') {
          stateColor = 'warning darken-2';
        } else if (visit.state === 'D' && debt < 0) {
          stateColor = 'error';
        } else if (visit.state === 'D') {
          stateColor = 'success';
        } else if (visit.state === 'Y' && debt < 0) {
          stateColor = 'error';
        } else if (visit.state === 'Y') {
          stateColor = 'success';
        } else if (visit.state === 'T') {
          stateColor = '#FFFFFF';
        }

        const timeFrom = new Date(visit.timeFrom || '');
        const timeTo = new Date(visit.timeTo || '');
        let size = 1;

        const diffMs = (timeTo.getTime() - timeFrom.getTime())
        if (diffMs > 900000 && diffMs <= 1800000) {
          size = 2;
        } else if (diffMs > 1800000) {
          size = 3;
        }

        let age = 'N/A';
        if (visit.patientBirthday) {
          age = String(new Date(Date.now() - new Date(visit.patientBirthday).getTime()).getFullYear() - 1970)
        }

        return {
          name: visit.patientName + ' (' + age + ')',
          start: visit.timeFrom,
          end: visit.timeTo,
          color: 'white',
          category: place ? place.name : '',
          visitColor: doctor ? doctor.color || '' : '',
          timed: true,
          timeFrom,
          timeTo,
          debt,
          size,
          visitStateColor: stateColor,
          doctor,
          place,
          visit
        }
      });
  }

  get categories(): any {
    return this.places.filter(place => this.selectedPlaces.length === 0 || this.selectedPlaces.some(p => p === place.id)).map(place => { return place.name });
  }

  get pickerDate(): string {
    return this.date.native(false);
  }

  set pickerDate(value: string) {
    this.changeDate(new Date(value));
  }

  get curDate(): any {
    return this.dateValue.native(false)
  }

  get date(): any {
    return this.dateValue
  }

  set date(value: any) {
    this.dateValue = new Date(value)
  }

  async updateDateSelector(month: string) {
    this.visitsMonth = await $app.get('/api/visits/range', {
      from: new Date(month + '-01').addMonth(-1).native(false),
      to: new Date(month + '-01').addMonth(1).native(false)
    });
  }

  get locale() {
    const l = this.system.$store.getCurrentUser.language || 'en';
    return l + '-' + l.toUpperCase();
  }

  calendarMove(data: any) {
    this.selectedY = Math.round(data.timeToY(data.time)) + 'px'
  }

  showVisit(data: any) {
    console.log('SHOW', data)
    this.visitId = data.event.visit.id;
    this.visitPatient = data.event.visit.patientId;
    this.visitPlace = null;
    this.visitDoc = null;
    this.visitFrom = null;
    this.visitTo = null;
    this.visitShow = true;
    if (data.nativeEvent) {
      data.nativeEvent.stopPropagation()
    }
  }

  addVisitWeek(data: any) {
    if (this.calendarType === 'week') {
      this.addVisit(data)
    }
  }

  addVisit(data: any) {
    const place = this.places.find(place => place.name === data.category)
    let min = data.minute
    while (min % 15 !== 0) {
      min--
    }
    const from = new Date(data.year, data.month - 1, data.day, data.hour, min)
    const to = new Date(from)
    to.addMin(30)

    this.visitId = null;
    this.visitPatient = null;
    this.visitPlace = place ? place.id : null;
    this.visitDoc = null;
    this.visitFrom = from;
    this.visitTo = to;
    this.visitShow = true;
  }

  async reloadVisits() {
    this.loading = true;
    try {
      this.doReloadVisits()
    } catch (err) {
      $app.pushError(err);
    }
    this.loading = false;
  }

  async doReloadVisits() {
    this.visits = await $app.get('/api/visits/range', {
      from: new Date(this.date).addDay(-30).native(false),
      to: new Date(this.date).addDay(30).native(false)
    });
    this.visitsMonth = this.visits
  }

  async reload() {
    this.loading = true;
    try {
      const doctors: Array<IUser> = (await $app.get('/api/clinics/doctors')) || [];
      this.doctors = doctors.filter(doc => doc.state === 'a');

      const places: Array<IPlace> = await $app.get('/api/clinics/places');
      const placesIndex: Record<number, IPlace> = {};
      places.forEach(p => {
        placesIndex[p.id || 0] = p;
        const docIndex: Record<number, IUser> = {};
        p.doctors.forEach(d => { docIndex[d.id || 0] = d });
        p.docIndex = docIndex;
      });
      this.placesIndex = placesIndex;
      this.places = places;

      await this.doReloadVisits();
    } catch (err) {
      $app.pushError(err);
    }
    this.loading = false;
    this.$nextTick(() => {
      this.scrollToTime()
    })
  }

  get nowY() {
    return this.calendarComponent ? this.calendarComponent.timeToY(this.calendarComponent.times.now) + 'px' : '-10px'
  }

  getCurrentTime() {
    return this.calendarComponent ? this.calendarComponent.times.now.hour * 60 + this.calendarComponent.times.now.minute : 0
  }

  scrollToTime() {
    const time = this.getCurrentTime()
    const first = Math.max(0, time - (time % 30) - 30)

    this.calendarComponent.scrollToTime(first)
  }

  updateTime() {
    setInterval(() => this.calendarComponent.updateTimes(), 60 * 1000)
  }

  mounted() {
    this.calendarComponent = this.$refs.calendar
    this.updateTime()
    this.reload();
  }
}
